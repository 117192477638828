import React, { Component } from 'react';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Resizer from 'react-image-file-resizer';

import { FaSearchLocation, FaSyncAlt } from "react-icons/fa";

// import LayoutSistema from '../rotas/homeRotas';
// import Header from '../header';

import { changeInitialState, changeInitialStateLogout, changeUltimaMarcacao } from '../actions/pontoAction';

import UploadFotoColaborador from './uploadFoto';

import { Spinner } from 'reactstrap';
/* Default position */
const DEFAULTPOSITION = {
    lat: 0,
    lng: 0
};

// var geolocation = new Geolocation();
// geolocation.setTracking(true);

const GetDateTime = async () =>
    await axios.get('https://worldtimeapi.org/api/timezone/America/Sao_Paulo',
        {
            withCredentials: false
        })
        .then(res => {
            return res.data.datetime;
        })
        .catch((error) => {
            return new Date();
        });

const formataEndereco = (endereco) => {

    if(endereco){
        var rua = endereco.road ? `${endereco.road}, `: "";
        var bairro = endereco.suburb ? `${endereco.suburb} `: "";
        var cep = endereco.postcode ? `- ${endereco.postcode}. ` : "";
        var cidade = endereco.city ? `${endereco.city}.` : "";

        return `${rua}${bairro}${cep}${cidade}`
    }
    return "";
}

Modal.setAppElement('#root');

class MarcaPontoHS extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultPosition: DEFAULTPOSITION,
            address: '',
            mensagemAddress: 'Carregando Localização ...',
            redirect: false,

            horaAtual: '00:00:00',
            dataAtual: '',
            imgLocalAtual: null,
            datetime: '',

            tipoErro: [],
            modalopenSucesso: false,
            modalopenErro: false,
            modalAguardaLocal: false,

            modalopen: false,
            imagePreviewUrl: '',

            mostraEndereco: true,
            imagemDefault: true,
            contadorSegundos: 0,

            horaMarcacao: this.props.horaUltimaMarcacao,
            dataMarcacao: this.props.dataUltimaMarcacao,

            loading: false,
            redirecionaHistorico: false
        };

        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.atribuiImagem = this.atribuiImagem.bind(this);

        this.enviaDados = this.enviaDados.bind(this);

        this.renderRedirect = this.renderRedirect.bind(this);
        this.deslogarSistema = this.deslogarSistema.bind(this);

        this.abreModalSucesso = this.abreModalSucesso.bind(this);
        this.fechaModalSucesso = this.fechaModalSucesso.bind(this);
        this.fechaModalAguardo = this.fechaModalAguardo.bind(this);

        this.abreModalErro = this.abreModalErro.bind(this);
        this.fechaModalErro = this.fechaModalErro.bind(this);

        this.abreModal = this.abreModal.bind(this);
        this.fechaModal = this.fechaModal.bind(this);

        this.formatData = this.formatData.bind(this);
        this.atualizaLocalizacao = this.atualizaLocalizacao.bind(this);

        this.visualizaLocalizacao = this.visualizaLocalizacao.bind(this);

        this.verificaExpirationDateTime = this.verificaExpirationDateTime.bind(this);
        this.sessionFinished = this.sessionFinished.bind(this);

        this.fechaModalSucessoSair = this.fechaModalSucessoSair.bind(this);
    }

    sessionFinished() {
        this.setState({
            tipoErro: "Sessão Expirada",
            loading: false
        });

        this.abreModalErro();
    }

    abreModal(e) {
        e.preventDefault();
        this.setState({ modalopen: true });
    }

    fechaModal() {
        this.setState({ modalopen: false });
    }

    abreModalSucesso() {
        this.setState({
            modalopenSucesso: true
        });
    }

    fechaModalSucessoSair() {
        this.setState({
            modalopenSucesso: false
        });
    }

    fechaModalSucesso() { // *Flor
        this.setState({
            modalopenSucesso: false,
            redirecionaHistorico: true
        });
    }

    fechaModalAguardo() {
        this.setState({
            modalAguardaLocal: false
        });
    }

    abreModalErro() {
        this.setState({
            modalopenErro: true
        });
    }

    fechaModalErro() {
        this.setState({
            modalopenErro: false
        });

        this.props.changeInitialStateLogout();
    }

    handleLocationChange(latitude, longitude) {

        axios.post('https://nominatim.openstreetmap.org/reverse', null,
        {
            params: {
                format: 'jsonv2',
                lat: latitude,
                lon: longitude,
                zoom: 18
            },
            timeout: 30000,
            withCredentials: false
        })
        .then(res => {
            if (res.status === 200) {
                var address = formataEndereco(res.data.address);

                this.setState({
                    address: latitude === 0 && longitude === 0 ? this.setState({ mostraEndereco: false }) : address,
                    mensagemAddress: ''
                });
            }
            this.setState({
                imgLocalAtual: '',
                loading: false
            })
        })
        .catch((error) => {
            console.log(error)
        })
    }

    atribuiImagem(img) {
        Resizer.imageFileResizer( //redimensionmento de imagem
            img, //arquivo
            350, //max. largura
            350, //max. altura
            'JPEG', //formato
            80, //qualidade
            0, //rotação
            ((uri) => {
                this.setState({ imgLocalAtual: uri, imagemDefault: false })
            }),
            'blob'
        );
    }

    atualizaLocalizacao() {
        this.verificaExpirationDateTime();

        this.setState({
            mensagemAddress: 'Carregando Localização ...'
        });

        navigator.geolocation.getCurrentPosition(position => {
            const { latitude, longitude } = position.coords;

            console.log(latitude, longitude)
            this.setState({
                defaultPosition: {
                    lat: latitude,
                    lng: longitude
                },
                mensagemAddress: ''
            });

            this.handleLocationChange(latitude, longitude)
        });
    }

    visualizaLocalizacao(){
        window.open(`https://www.google.com/maps/search/?api=1&query=${this.state.defaultPosition.lat},${this.state.defaultPosition.lng}`)
    }

    componentDidMount() {
        var dateTimeJorge = new Date();

        GetDateTime().then(res => {
            if (res !== undefined) {
                dateTimeJorge = new Date(res);
            }
            else {
                dateTimeJorge = new Date();
            }
        });

        if ("geolocation" in navigator) {
            if (this.props.colaborador.LocalizacaoMobile === true) {
                this.atualizaLocalizacao();
            }
            else {
                this.setState({
                    mostraEndereco: false
                });
            }
        }
        else {
            this.setState({
                mostraEndereco: false
            });
        }

        this.interval2 = setInterval(() => {
            GetDateTime().then(res => {
                if (res !== undefined) {
                    dateTimeJorge = new Date(res);
                }
                else {
                    dateTimeJorge = new Date();
                }
            })
                .catch(error => {
                    dateTimeJorge = new Date();
                })

        }, 60000);

        //Inicia o horario e a data de acordo com o dispositivo e atualiza a cada 1 sec
        this.interval = setInterval(() => {

            this.verificaExpirationDateTime();

            // if(sessionExpired){
            //     console.log('sessionExpired')
            //     this.sessionFinished();
            // }

            let hora = dateTimeJorge.toLocaleTimeString();
            let data = dateTimeJorge.toLocaleDateString();

            this.setState({
                horaAtual: hora,
                dataAtual: data,
                contadorSegundos: this.state.contadorSegundos + 1
            });

            dateTimeJorge.setSeconds(dateTimeJorge.getSeconds() + 1);
        }, 986.7);
    }

    componentWillUnmount() {
        //Para evitar erro após o logout do usuário limpamos o intervalo de atualização
        clearInterval(this.interval);
        clearInterval(this.interval2);
        //this.props.changeInitialState();
    }

    renderRedirect() {
        this.setState({ redirect: true });
    }

    enviaDados(event) {
        event.preventDefault();
        var sessionExpired = this.verificaExpirationDateTime();

        if (!sessionExpired) {
            this.setState({
                loading: true
            })

            if (this.props.colaborador.LocalizacaoMobile && this.state.defaultPosition.lat === 0 &&
                this.state.defaultPosition.lng === 0 && this.state.contadorSegundos < 5) {

                this.setState({ modalAguardaLocal: true });
            }
            else {
                let cookie = new Cookies();
                let token = cookie.get('token');

                let formData = new FormData(); //cria um objeto de diferentes tipos de dados (Ex.: string e imagem)

                formData.append('imagem', this.state.imgLocalAtual); //nomeia o campo que está sendo adicionado

                //Demonsta a hora e monta no formato hh:mm para não inserir duplas marcações por minuto
                let hora = this.state.horaAtual.split(":");
                let horaMarcacao = (hora[0] + ':' + hora[1]);

                let objetoData = {
                    Hora: horaMarcacao,
                    DataMarcacao: this.state.dataAtual,
                    PIS: this.props.colaborador.PIS,
                    Latitude: this.state.defaultPosition.lat === 0 ? null : this.state.defaultPosition.lat,
                    Longitude: this.state.defaultPosition.lng === 0 ? null : this.state.defaultPosition.lng
                }

                formData.append('dadosmarcacao', JSON.stringify(objetoData)); //transforma em Json string

                if (this.props.colaborador.PIS !== undefined) {
                    // axios.post(process.env.REACT_APP_URL_REQUISICOES, formData,
                    axios.post(process.env.REACT_APP_URL_REQUISICOES_V2 + 'marcacaoWeb', formData,
                        {
                            timeout: 30000,
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': 'Bearer ' + token
                            }
                        })
                        .then(res => {
                            if (res.status === 201) {
                                this.abreModalSucesso();

                                let arrayData = this.state.dataAtual.split('/');
                                let dataFormatada = arrayData[2] + '-' + arrayData[1] + '-' + arrayData[0] + 'T00:00:00' //Data no ofrmato yyyy-mm-dd

                                //Seta imagem default depois da requisição
                                this.setState({
                                    imagemDefault: true,
                                    horaMarcacao: horaMarcacao,
                                    dataMarcacao: dataFormatada
                                })

                                this.props.changeUltimaMarcacao(dataFormatada, horaMarcacao);
                            }
                            this.setState({
                                imgLocalAtual: '',
                                loading: false
                            })
                        })
                        .catch((error) => {
                            if (error.response !== undefined) {
                                if (error.response.status === 401) {
                                    this.setState({
                                        tipoErro: "Sessão Expirada",
                                        loading: false
                                    });
                                }
                                else {
                                    this.setState({
                                        tipoErro: error.response.data,
                                        loading: false
                                    });
                                }
                                this.abreModalErro();
                            }
                            else {
                                this.setState({
                                    tipoErro: "Não foi possível conectar com o servidor",
                                    loading: false
                                })
                                this.abreModalErro();
                            }
                        })
                }
                else {
                    this.setState({
                        tipoErro: 'Colaborador não identificado. Tente logar novamente'
                    });
                    this.abreModalErro();
                }
            }
        }
        else {
            this.setState({
                tipoErro: "Sessão Expirada",
                loading: false
            });

            this.abreModalErro();
        }
    }

    deslogarSistema() {
        let cookies = new Cookies('token');

        cookies.remove('token', { path: '/' });
        cookies.remove('ExpirationDateTime', { path: '/' });

        this.props.changeColaborador([]);

        //this.renderRedirect();
    }

    formatData() {
        let objetoDate = new Date(this.state.dataMarcacao);

        if (objetoDate.getFullYear() > 1) {
            let dia = objetoDate.getDate();
            let mes = objetoDate.getMonth() + 1;
            let ano = objetoDate.getFullYear();

            return dia + '/' + mes + '/' + ano + ' | ';
        }
        else {
            return undefined;
        }
    }

    verificaExpirationDateTime() {
        let cookie = new Cookies();

        var cookieDate = new Date(cookie.get("ExpirationDateTime"));

        if (cookieDate.getTime() < Date.now()) {
            this.sessionFinished();
        }
    }

    render() {
        //Foi feito isso, pois se o colaborador do redux for vazio, é necessário redirecionar para a pagina de login
        if (this.props.colaborador.length === 0) {
            return <Redirect to='/' />
        }
        if (this.state.redirecionaHistorico === true) {
            return <Redirect to='/Home/MarcaPonto/Historico' />
        }

        let redirecionamento = this.state.redirect ? <Redirect to='/' /> : null;
        let estilo =
            this.state.modalopenErro ? "row justify-content-center opacidade h-75" : "row justify-content-center h-75"
                &&
                this.state.modalopenSucesso ? "row justify-content-center opacidade h-75" : "row justify-content-center h-75"

        const botao = this.state.horaAtual !== '00:00:00' && this.state.dataAtual !== '' ?
            <div className="col">
                <button
                    type="button"
                    onClick={this.enviaDados}
                    className="btn corBotaoMarcaPonto rounded-pill col">
                    Marcar Ponto
            </button>
            </div>
            :
            null;

        let textAreaEndereco = this.state.address + '\r\n' + this.state.mensagemAddress;

        let exibeEndereco = this.state.mostraEndereco === false ?
            null
            :
            <div className="form-group">
                <label htmlFor="localizacao_colaborador" className="labelMarcaPonto">Endereço</label>
                <textarea
                    name="localizacao_colaborador"
                    id="localizacao_colaborador"
                    value={textAreaEndereco}
                    className="form-control form-resize text-center estiloendereco"
                    disabled="disabled"
                    rows="3"
                    maxLength="150">
                </textarea>
            </div>;

        return (
            <div className="fundoMarcaPonto">
                <Modal  //Modal de erro
                    isOpen={this.state.modalopenErro}
                    className="modal-sucesso col-10 col-md-8 col-lg-6 col-xl-4 text-center"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center eventodiv">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p>{this.state.tipoErro}</p>}
                        <div className="row">
                            <div className="col">
                                <Link to="/"><button onClick={this.fechaModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de sucesso
                    isOpen={this.state.modalopenSucesso}
                    // onRequestClose={this.fechaModalSucesso}
                    className="modal-sucesso col-10 col-md-8 col-lg-6 col-xl-4 text-center"
                    overlayClassName="overlay"
                >
                    <div className="text-center">
                        <label>Marcação registrada com sucesso!</label><br />
                        <label>Deseja ver seu histórico?</label>

                        <div className="row">
                            <div className="col text-right">
                                <button onClick={this.fechaModalSucesso} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Sim</button>
                            </div>

                            <div className="col text-left">
                                <button onClick={this.fechaModalSucessoSair} className="btn btn-outline-danger btn-sm col-10 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal //Modal de saida
                    isOpen={this.state.modalopen}
                    // onRequestClose={this.fechaModal}
                    className="modal-sucesso col-10 col-md-8 col-lg-6 col-xl-4 text-center"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Certeza que deseja sair? </label>
                        <div className="row">
                            <div className="col text-right">
                                <button onClick={this.deslogarSistema} className="btn btn-outline-primary btn-sm col-10 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Sim</button>
                            </div>

                            <div className="col text-left">
                                <button onClick={this.fechaModal} className="btn btn-outline-danger btn-sm col-10 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal Aguardando Localização
                    isOpen={this.state.modalAguardaLocal}
                    // onRequestClose={this.fechaModalAguardo}
                    className="modal-sucesso col-10 col-md-8 col-lg-6 col-xl-4 text-center"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label>Aguardando Localização</label><br />

                        <div className="row">
                            <div className="col">
                                <button onClick={this.fechaModalAguardo} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal  //Modal de loading
                    isOpen={this.state.loading}
                    className="modal-sucesso col-10 col-md-8 col-lg-6 col-xl-4 text-center"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center eventodiv">
                        <label> Marcando Ponto... </label><br />
                        <Spinner color="primary" size="lg" />
                    </div>
                </Modal>

                <div className={estilo}>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 marginTop marginBottom">
                        <div className="cardMarcaPonto">
                            <form encType="multipart/form-data">
                                <div className="form-row  justify-content-center">
                                    <UploadFotoColaborador ImagemDefault={this.state.imagemDefault} changeImagem={this.atribuiImagem} />

                                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                        <div className="form-row">
                                            <div className="form-group col">
                                                <label htmlFor="dataMarcaPonto" className="labelMarcaPonto">Data Atual</label>
                                                <input
                                                    name="dataMarcaPonto"
                                                    id="dataMarcaPonto"
                                                    className="form-control text-center"
                                                    type="text"
                                                    disabled="disabled"
                                                    value={this.state.dataAtual}
                                                />
                                            </div>
                                            <div className="form-group col" >
                                                <label htmlFor="horaMarcaPonto" className="labelMarcaPonto">Hora Atual</label>
                                                <input
                                                    name="horaMarcaPonto"
                                                    id="horaMarcaPonto"
                                                    className="form-control text-center"
                                                    type="time"
                                                    disabled="disabled"
                                                    value={this.state.horaAtual}
                                                />
                                            </div>
                                        </div>

                                        {exibeEndereco}

                                        <div className="form-row margemTop margemBottom">
                                            {botao}
                                            <div className="col-2">
                                                <button
                                                    type="button"
                                                    onClick={this.visualizaLocalizacao}
                                                    className="btn rounded-pill btn-secondary col"
                                                    title="Localização no Maps">
                                                    <FaSearchLocation size={20} className="text-white icon-atualizalocalizacao" />
                                                </button>
                                            </div>
                                            <div className="col-2">
                                                <button
                                                    type="button"
                                                    onClick={this.atualizaLocalizacao}
                                                    className="btn rounded-pill btn-secondary col"
                                                    title="Atualizar localização">

                                                    <FaSyncAlt size={20} className="text-white icon-atualizalocalizacao" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {redirecionamento}
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProp = (state) => ({
    colaborador: state.dadosPonto.colaborador,
    dataUltimaMarcacao: state.dadosPonto.dataUltimaMarcacao,
    horaUltimaMarcacao: state.dadosPonto.horaUltimaMarcacao
});

const mapActionstoProps = {
    changeInitialState,
    changeInitialStateLogout,
    changeUltimaMarcacao
}

export default connect(mapStateToProp, mapActionstoProps)(MarcaPontoHS);
import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Router } from 'react-router';
import { createBrowserHistory } from "history";

import Rotas from './components/rotas'

const history = createBrowserHistory();

//const store = createStore(Reducers); //criação do store

function App() {
  return (
    <div>
        <BrowserRouter>
          <Router history={history}>
            <Rotas />
          </Router>
        </BrowserRouter>
    </div>
  );
}

export default App;
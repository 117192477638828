import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'

import axios from 'axios';
import Cookies from 'universal-cookie';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { changeColaborador, changeUltimaMarcacao, changeUsername, changeInitialStateLogout, changeIDEmpresa, changeConectado, changeInitialState } from '../actions/pontoAction';
import { Spinner } from 'reactstrap';
import CheckBox from 'react-simple-checkbox';

import Logo from '../../img/logoMarcaPonto.webp';

Modal.setAppElement('#root');

class LoginUsuario extends Component {
    constructor(props) {
        super(props);

        this.state = {
            idEmpresa_Login: this.props.idEmpresa_Login,
            username: this.props.username,
            senha_Login: '',
            username_erro: '',
            conectado: false,

            redirect: false,
            tipoErro: [],
            modalopenErro: false,
            colaborador: [],
            loading: false,
            alteraSenha: false,
            expirationDateTime: ''
        }

        this.atribuiIdEmpresa = this.atribuiIdEmpresa.bind(this);
        this.atribuiUsername = this.atribuiUsername.bind(this);
        this.atribuiSenha = this.atribuiSenha.bind(this);
        this.atribuiConectado = this.atribuiConectado.bind(this);

        this.logarSistema = this.logarSistema.bind(this);
        this.abreModalErro = this.abreModalErro.bind(this);
        this.fechaModalErro = this.fechaModalErro.bind(this);
        this.fechaModalLoading = this.fechaModalLoading.bind(this);
    }

    componentDidMount() {
        if (this.props.conectado === false) {
            this.setState({
                idEmpresa_Login: '',
                username: ''
            })
            this.props.changeInitialState();
        }

        this.props.changeInitialStateLogout()
    }

    abreModalErro() {
        this.setState({
            modalopenErro: true
        });
    }

    fechaModalErro() {
        this.setState({
            modalopenErro: false
        });
        this.props.changeInitialStateLogout();
    }

    atribuiIdEmpresa(event) {
        let idEmpresa_Login = event.target.value;
        idEmpresa_Login = idEmpresa_Login.replace(/[^\d]+/g, '');

        this.setState({ idEmpresa_Login: idEmpresa_Login })
        this.props.changeIDEmpresa(idEmpresa_Login);
    }

    atribuiUsername(event) {
        let username = event.target.value;

        this.setState({ username: username });
        this.props.changeUsername(username);
    }

    atribuiSenha(event) {
        let senha = event.target.value;

        this.setState({ senha_Login: senha })
    }

    atribuiConectado(conectado) {
        this.setState({ conectado });
        this.props.changeConectado(conectado);
    }

    logarSistema(e) {
        this.setState({ loading: true })
        e.preventDefault();

        axios.defaults.withCredentials = true; // credencial para envio de cookies

        //axios.post(process.env.REACT_APP_URL_LOGIN_EXTERNO,
        axios.post(process.env.REACT_APP_URL_LOGIN,
            {
                IDFront: this.props.idEmpresa_Login,
                UserName: this.props.username,
                Senha: this.state.senha_Login
            },
            {
                timeout: 30000,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => { //recurso usado como parametro para o recebimento das informações do token
                // this.props.changePIS(res.data.colaborador.PIS);
                // this.props.changeNome(res.data.colaborador.Nome);
                // this.props.changeLocalizacaoMobile(res.data.colaborador.LocalizacaoMobile);
                // this.props.changeFotoMobile(res.data.colaborador.FotoMobile);

                this.props.changeUltimaMarcacao(res.data.datamarcacao, res.data.horamarcacao.toString().substring(0, 5));
                this.props.changeColaborador(res.data.colaborador);

                if (res.status === 200) {
                    const cookies = new Cookies();
                    const date = new Date(res.data.expirationDateTime);
                    //date.setHours(date.getHours() + 10); //Determina a hora que o token vai durar

                    cookies.set('token', res.data.token,
                        { path: '/' });

                    cookies.set('ExpirationDateTime', date,
                        { path: '/' });

                    this.setState({
                        redirect: true,
                        loading: false,
                        alteraSenha: res.data.alterasenha,
                        colaborador: res.data.colaborador
                    })
                }
                else {
                    let username_erro = this.state.username_erro;
                    username_erro = 'Login Inválido';
                    this.setState({ username_erro });

                    let cookies = this.state.cookies;
                    cookies.remove('token');
                }
            })
            .catch((error) => {
                if (error.response !== undefined) {
                    if (error.response.status === 401) {
                        this.setState({
                            tipoErro: "Credenciais inválidas",
                            loading: false
                        });
                    }
                    else {
                        this.setState({
                            //tipoErro: error.response.data === '' ? "Credenciais inválidas" : error.response.data
                            tipoErro: "Entre em contato com o Administrador",
                            loading: false
                        });
                    }
                    this.abreModalErro();
                }
                else {
                    this.setState({
                        loading: false,
                        tipoErro: "Não foi possivel conectar com o servidor"
                    });
                    this.abreModalErro();
                }
            });
    }

    fechaModalLoading() {
        this.setState({ loading: false })
    }

    renderRedirect() {
        if (this.state.redirect === true) {
            if (this.state.alteraSenha === false) {
                return <Redirect to='/Home/MarcaPonto' />
            }
            else {
                return <Redirect to='/Home/NovaSenha' />
            }

        }
    }

    render() {
        return (
            <div className="row justify-content-center fundoLogin">
                <Modal  //Modal de erro
                    isOpen={this.state.modalopenErro}
                    // onRequestClose={this.fechaModalErro}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center eventodiv">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p>{this.state.tipoErro}</p>}
                        <div className="row">
                            <div className="col">
                                <Link to="/"><button onClick={this.fechaModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal  //Modal de Loading
                    isOpen={this.state.loading}
                    // onRequestClose={this.fechaModalLoading}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center">
                        <label> Conectando... </label><br />
                        <Spinner color="primary" size="lg" />
                    </div>
                </Modal>

                <div className="col-12 col-sm-11 col-md-8 col-lg-6 col-xl-4 my-auto">
                    <div className="cardLogin">
                        <div className="h1 text-center">
                            <img
                                src={Logo}
                                className="tamanhoLogo margin-bottom-rotate text-center"
                                alt="foto_colaborador" />
                        </div>

                        <form onSubmit={this.logarSistema} autoComplete="on">
                            <div className="form-row">
                                <div className="form-group col-12 col-sm-5 col-md-4 col-lg-4 col-xl-4">
                                    <label htmlFor="idEmpresa_Login" className="labelLogin">ID Empresa:</label>
                                    <input
                                        className="form-control rounded-pill"
                                        type="text"
                                        id="idEmpresa_Login"
                                        name="idEmpresa_Login"
                                        onChange={this.atribuiIdEmpresa}
                                        value={this.state.idEmpresa_Login}
                                        // value={this.props.idEmpresa_Login}
                                        maxLength="5"
                                        required="required"
                                    />
                                </div>

                                <div className="form-group col-12 col-sm-7 col-md-8 col-lg-8 col-xl-8">
                                    <label htmlFor="username" className="labelLogin">Username:</label>
                                    <input
                                        type="text"
                                        id="username"
                                        name="username"
                                        onChange={this.atribuiUsername}
                                        value={this.state.username}
                                        // value={this.props.username}
                                        className='form-control rounded-pill'
                                        maxLength="40"
                                        required="required"
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="senha_Login" className="labelLogin">Senha:</label>
                                <input
                                    className="form-control rounded-pill"
                                    type="password"
                                    id="senha_Login"
                                    name="senha_Login"
                                    onChange={this.atribuiSenha}
                                    value={this.state.senha_Login}
                                    maxLength="30"
                                    required="required"
                                />
                            </div>

                            <div className="form-row">
                                <div className="form-group">
                                    <div className="col">
                                        <CheckBox
                                            id='conectado'
                                            size={4}
                                            tickSize={3}
                                            borderThickness={1}
                                            checked={this.props.conectado}
                                            onChange={this.atribuiConectado}
                                            color={{ backgroundColor: '#007bef', borderColor: '#007bef', uncheckedBorderColor: '#ced4da' }}
                                            tickAnimationDuration={100}
                                            backAnimationDuration={100} />
                                        <label htmlFor='conectado' className="alinhamentoCheck align-middle">Mantenha-me conectado</label>
                                    </div>
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="btn btn-entrar btn-lg btn-block rounded-pill"
                            >
                                Entrar
                            </button>
                        </form>

                        <div className="termoUsoDiv">
                            <div className="labelTermosUso">
                                Acessando e utilizando o MarcaPontoWeb, você concorda com seus termos de uso
                                e política de privacidade. Você pode ler ambos através do link abaixo.
                            </div>
                            <div className="linkTermosUso">
                                <a className="text-white" href="https://hssistema.com/Privacidade" target="_blank" rel="noopener noreferrer"> Termos de Uso e Política de Privacidade</a>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderRedirect()}
            </div>
        );
    }
}
const mapStateToProp = (state) => ({
    colaborador: state.dadosPonto.colaborador,
    username: state.dadosPonto.username,
    idEmpresa_Login: state.dadosPonto.idEmpresa_Login,
    conectado: state.dadosPonto.conectado
});

const mapActionstoProps = {
    changeColaborador,
    changeUltimaMarcacao,
    changeUsername,
    changeIDEmpresa,
    changeConectado,
    changeInitialState,
    changeInitialStateLogout
}

export default connect(mapStateToProp, mapActionstoProps)(LoginUsuario);
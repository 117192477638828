import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Modal from 'react-modal';
import { changeInitialStateLogout } from '../actions/pontoAction';

import { FaRegEye } from 'react-icons/fa';
import { connect } from 'react-redux';


class AlterarSenha extends Component {
    constructor(props) {
        super(props);

        this.state = {
            novaSenha: '',
            novasenha_erro: '',
            confirmacaoSenha: '',
            confirmacaoSenha_erro: '',
            modalOpenSucesso: false,
            modalopenErro: false,
            modalOpenRefresh: false,
            redireciona: false,
            tipoErro: [],
            mostraSenha: false
        }

        this.atribuiSenha = this.atribuiSenha.bind(this);
        this.alteraSenha = this.alteraSenha.bind(this);
        this.abreModalSucesso = this.abreModalSucesso.bind(this);
        this.fechaModalSucesso = this.fechaModalSucesso.bind(this);
        this.abreModalErro = this.abreModalErro.bind(this);
        this.fechaModalErro = this.fechaModalErro.bind(this);
        this.atribuiConfirmacao = this.atribuiConfirmacao.bind(this);
        this.confereSenha = this.confereSenha.bind(this);
        this.mostraSenha = this.mostraSenha.bind(this);

        this.validaSenha = this.validaSenha.bind(this);

        this.ModalRefresh = this.ModalRefresh.bind(this);
    }

    ModalRefresh() {
        this.setState({ modalOpenRefresh: true })
    }

    mostraSenha() {
        this.setState({ mostraSenha: !this.state.mostraSenha })
    }

    confereSenha(confirmacaoParametro, senhaParametro) {
        let confirmacaoSenha = this.state.confirmacaoSenha;
        let novaSenha = this.state.novaSenha;

        if (confirmacaoParametro) {
            confirmacaoSenha = confirmacaoParametro;
        }

        if(senhaParametro){
            novaSenha = senhaParametro
        }

        let confirmacaoSenha_erro = novaSenha === confirmacaoSenha ? '' : "Senhas não coincidem";
        this.setState({ confirmacaoSenha_erro });
    }

    atribuiConfirmacao(event) {
        this.setState({ confirmacaoSenha: event.target.value })

        this.confereSenha(event.target.value);
    }

    atribuiSenha(event) {
        this.setState({ novaSenha: event.target.value })

        this.validaSenha(event.target.value);
        this.confereSenha(null, event.target.value);
    }

    validaSenha(senhaParametro) {
        let novaSenha = this.state.novaSenha;

        if (senhaParametro) {
            novaSenha = senhaParametro;
        }

        let novasenha_erro = '';

        if (!novaSenha.match(/^[a-z0-9]+$/i) && novaSenha !== '') {
            novasenha_erro = 'A senha deve conter apenas letras e números';
        }
        else if (novaSenha.length < 3) {
            novasenha_erro = 'A senha deve conter mais de 3 caracteres';
        }
        else {
            novasenha_erro = '';
        }

        this.setState({ novasenha_erro })
    }

    abreModalSucesso() {
        this.setState({ modalOpenSucesso: true })
    }

    fechaModalSucesso() {
        this.setState({ modalOpenSucesso: false })
    }

    abreModalErro() {
        this.setState({
            modalopenErro: true
        });
    }

    fechaModalErro() {
        this.setState({
            modalopenErro: false
        });

        this.props.changeInitialStateLogout();
    }

    alteraSenha(e) {
        e.preventDefault();

        if (this.state.novasenha_erro === '' && this.state.confirmacaoSenha_erro === '') {

            let cookie = new Cookies();
            let token = cookie.get("token");

            //this.setState({ loading: true })

            axios.defaults.withCredentials = true; // credencial para envio de cookies

            axios.post(process.env.REACT_APP_URL_REQUISICOES + 'auth/changesenha',
                {
                    senhaNova: this.state.novaSenha
                },
                {
                    timeout: 30000,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                })
                .then((res) => {
                    if (res.status === 200) {
                        this.abreModalSucesso();
                    }
                })
                .catch((error) => {
                    if (error.response !== undefined) {
                        if (error.response.status === 401) {
                            this.setState({
                                tipoErro: "Sessão Expirada",
                                loading: false
                            });

                            this.ModalRefresh();
                        }
                        else {
                            this.setState({
                                tipoErro: 'Erro não identificado. Contate o administrador!',
                                loading: false
                            });
                        }
                        this.ModalRefresh();
                    }
                    else {
                        this.setState({
                            tipoErro: "Não foi possível conectar com o servidor",
                            loading: false
                        })
                        this.ModalRefresh();
                    }
                });
        }
        else {
            this.setState({
                tipoErro: "Campos inválidos",
                mensagemModal: 'Atenção!',
                loading: false
            });

            this.abreModalErro();
        }
    }
    render() {
        return (
            <div className="row justify-content-center fundoLogin">
                <Modal  //Modal de sucesso
                    isOpen={this.state.modalOpenSucesso}
                    onRequestClose={this.fechaModalSucesso}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center eventodiv">
                        <label> Senha alterada com sucesso. </label><br />
                        <label> Faça o login novamente. </label><br />
                        <div className="row">
                            <div className="col">
                                <Link to="/"><button onClick={this.fechaModalSucesso} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal  //Modal de erro
                    isOpen={this.state.modalopenErro}
                    onRequestClose={this.fechaModalErro}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center eventodiv">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p>{this.state.tipoErro}</p>}
                        <div className="row">
                            <div className="col">
                                <button onClick={this.fechaModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button>

                                {/* <Link to="/"><button onClick={this.fechaModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link> */}
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal  //Modal de erro refresh
                    isOpen={this.state.modalOpenRefresh}
                    //onRequestClose={this.ModalRefresh}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center eventodiv">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p> {this.state.tipoErro} </p>}
                        <div className="row">
                            <div className="col">
                                <Link to="/"><button onClick={this.fechaModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className="col-12 col-sm-11 col-md-8 col-lg-6 col-xl-4">
                    <div className="card">
                        <div className="card-header">
                            <h5>Alterar senha:</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={this.alteraSenha} className="card-text">
                                <div className="form-row">
                                    <div className="form-group col-12">
                                        <p className="card-title">Digite nova senha:</p>
                                        <div className="input-group">
                                            <input
                                                type={this.state.mostraSenha === true ? "text" : "password"}
                                                id="novaSenha"
                                                name="novaSenha"
                                                value={this.state.novaSenha}
                                                onChange={this.atribuiSenha}
                                                onBlur={() => this.validaSenha(null)}
                                                className={this.state.novasenha_erro ? 'form-control is-invalid' : 'form-control'}
                                                required
                                                autoFocus />
                                            <div className="input-group-prepend">
                                                <button type="button" tabIndex="-1" onMouseDown={this.mostraSenha} onMouseUp={this.mostraSenha} className="input-group-text"><FaRegEye /></button>
                                            </div>

                                            <div className="invalid-feedback"> {this.state.novasenha_erro}</div>
                                        </div>
                                    </div>

                                    <div className="form-group col-12">
                                        <p className="card-title">Confirme nova senha:</p>
                                        <input
                                            type="password"
                                            id="confirmaSenha"
                                            name="confirmaSenha"
                                            value={this.state.confirmacaoSenha}
                                            onChange={this.atribuiConfirmacao}
                                            onBlur={() => this.confereSenha(null, null)}
                                            className={this.state.confirmacaoSenha_erro ? 'form-control is-invalid' : 'form-control'}
                                            required />
                                        <div className='invalid-feedback'>{this.state.confirmacaoSenha_erro}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col text-right">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-sm col-7 col-sm-6 col-md-5 col-lg-4 col-xl-4">
                                            Continuar
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProp = (state) => ({
    colaborador: state.dadosPonto.colaborador
});

const mapActionstoProps = {
    changeInitialStateLogout
}

export default connect(mapStateToProp, mapActionstoProps)(AlterarSenha);
import React, { Component } from 'react';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
import Modal from 'react-modal';
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';

import { changeHistorico } from '../actions/pontoAction';

class Historico extends Component {
    constructor(props) {
        super(props);

        this.state = {
            arrayMarcacoes: [],
            tipoErro: [],
            redirect: false,
            loading: true,
            erro: false
        };

        this.atualizaHistorico = this.atualizaHistorico.bind(this);
        this.pontuaData = this.pontuaData.bind(this);
        this.ajustaHora = this.ajustaHora.bind(this);
        this.renderRedirect = this.renderRedirect.bind(this);

        this.ModalErro = this.ModalErro.bind(this);
    }

    renderRedirect() {
        this.setState({ redirect: true });
        this.props.changeInitialStateLogout();
    }

    atualizaHistorico() {
        let cookie = new Cookies();
        let token = cookie.get("token");
        let empresa = this.props.colaborador.IDEmpresa;
        //Faz requisição para trazer as marcaçõesp ara o historico
        
        axios.get(process.env.REACT_APP_URL_REQUISICOES_V2 + empresa + "/marcacoes/" + this.props.colaborador.PIS,
            {
                timeout: 30000,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(res => {
                //armazena as marcacoes no historicoMarcacoes no redux
                changeHistorico(res.data);

                this.setState({
                    arrayMarcacoes: res.data,
                    loading: false
                });
            })
            .catch((error) => {
                if (error.response !== undefined) {
                    if (error.response.status === 401) {
                        this.setState({
                            tipoErro: "Sessão Expirada",
                            loading: false
                        });
                    }
                    else {
                        this.setState({
                            tipoErro: error.response.data,
                            loading: false
                        });
                    }
                    this.ModalErro();
                }
                else {
                    this.setState({
                        tipoErro: "Não foi possível conectar com o servidor",
                        loading: false
                    });
                }
                this.ModalErro();
            });
    }

    pontuaData(dataJson) {
        let separaDataHora = dataJson.split("T");

        let DataMarcacao = separaDataHora[0].split("-");
        let Data = DataMarcacao[2] + '/' + DataMarcacao[1] + '/' + DataMarcacao[0];

        return Data;
    }

    ajustaHora(horaJson) {
        let HoraMarcacao = horaJson.split(":");
        let Hora = HoraMarcacao[0] + ':' + HoraMarcacao[1];

        return Hora;
    }

    componentDidMount() {
        //verifico se o historico está vazio, se sim é feita a requisição
        if (this.props.historicoMarcacoes.length === 0) {
            this.atualizaHistorico();
        }
    }

    ModalErro() {
        this.setState({ erro: !this.state.erro });
    }

    render() {
        //irá cair aqui se a sessão tiver sido expirada.
        if (this.state.redirect) {
            return <Redirect to='/' />
        }

        if (this.props.colaborador.length === 0) {
            return <Redirect to='/' />
        }
        var contentTable = this.state.loading ?
            <div className="float-center text-center eventodiv">
                <label> Carregando dados... </label><br />
                <Spinner color="primary" size="lg" />
            </div> :
            <table className="table text-center table-md">
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Hora</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.arrayMarcacoes.map((item, index) =>
                        <tr key={index}>
                            <td>{this.pontuaData(item.DataMarcacao)}</td>
                            <td>{this.ajustaHora(item.Hora)}</td>
                        </tr>
                    )}
                </tbody>
            </table>;

        return (
            <div className="fundoMarcaPonto">
                <Modal  //Modal de erro
                    isOpen={this.state.erro}
                    className="modal-sair col-10 col-md-8 col-lg-6 col-xl-4 text-center"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center eventodiv">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p>{this.state.tipoErro}</p>}
                        <div className="row">
                            <div className="col">
                                <Link to="/"><button onClick={this.ModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 margemTop text-center">
                        <div className="table-responsive overflow">
                            {contentTable}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProp = (state) => ({
    colaborador: state.dadosPonto.colaborador,
    historicoMarcacoes: state.dadosPonto.historicoMarcacoes
});

const mapActionsToProps = {
    changeHistorico
}

export default connect(mapStateToProp, mapActionsToProps)(Historico);
import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'

import SelfieTeste from '../../img/imagemDefault.png';

import { FaCameraRetro } from "react-icons/fa";
import { IoIosRefresh } from "react-icons/io";

import Cookies from 'universal-cookie';


export default class UploadFotoColaborador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: '',
            imagePreviewUrl: '',
            rotation: 0
        };

        this.rotate = this.rotate.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.verificaExpirationDateTime = this.verificaExpirationDateTime.bind(this);
    }

    verificaExpirationDateTime() {
        let cookie = new Cookies();

        var cookieDate = new Date(cookie.get("ExpirationDateTime"));

        if (cookieDate.getTime() > Date.now()) {
            return false;
        }
        else {
            return true;
        }
    }

    handleImageChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let imagePreviewUrl = '';

        if (file !== undefined) {
            reader.onloadend = () => {
                imagePreviewUrl = reader.result;
                this.setState({
                    file: file,
                    imagePreviewUrl
                });
            }

            reader.readAsDataURL(file)

            this.props.changeImagem(file);
        }

    }

    rotate() {
        let newRotation = this.state.rotation + 90;

        if (newRotation >= 360) {
            newRotation = - 360;
        }

        this.setState({
            rotation: newRotation,
        })
    }

    render() {
        const { rotation } = this.state;
        let imagePreview = null;

        //Alterar isso aqui. Setar a imagem padrão no state.
        if (!this.props.ImagemDefault) {
            imagePreview = (
                <img
                    style={{ transform: `rotate(${rotation}deg)` }}
                    src={this.state.imagePreviewUrl}
                    className="img-thumbnail tamanhoFoto margin-bottom-rotate"
                    alt="foto_colaborador" />
            );
        }
        else {
            imagePreview = (
                <img
                    style={{ transform: `rotate(${rotation}deg)` }}
                    src={SelfieTeste}
                    className="img-thumbnail tamanhoFoto margin-bottom-rotate"
                    alt="foto_colaborador" />
            );
        }

        return (
            <div className="col-12 col-sm-12 col-md-4 col-xl-3 col-lg-3 tamanho-componenteFoto">
                <div className="form-group text-center">
                    <div>
                        {imagePreview}
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-6 col-sm-6 col-lg-6">
                        <div className="upload-btn-wrapper">
                            <div className="btn btn-upload" data-tip="Selecione uma foto">
                                <FaCameraRetro size={32} className="" />
                                <input
                                    name="foto_colaborador"
                                    type="file"
                                    accept="image/*"
                                    onChange={this.handleImageChange}
                                />
                            </div>
                            <ReactTooltip place="top" type="dark" effect="solid" />
                        </div>
                    </div>

                    <div className="form-group col-6 col-sm-6 col-lg-6">
                        <div className="upload-btn-wrapper btn-giro">
                            <button onClick={this.rotate}
                                data-tip="Girar foto"
                                type="button"
                                className="btn btn-upload btn-giro">
                                <IoIosRefresh size={32} className="" />
                            </button>
                            <ReactTooltip place="top" type="dark" effect="solid" />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
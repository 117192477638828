import React, { Component } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
// import axios from 'axios';
// import Cookies from 'universal-cookie';
import Modal from 'react-modal';
import { Provider } from 'react-redux';

import Home from './homeRotas'
import LoginUsuario from '../login/loginMain';
import Footer from '../footer';
import AlterarSenha from '../senha/mainAltera_senha';

import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from '../../configureStore'

Modal.setAppElement('#root');

class Rotas extends Component {
    // constructor(props) { Alterado *Flor
    //     super(props);

    //     this.state = {
    //         modalOpenErro: false,
    //         tipoErro: []
    //     }

    //     this.abreModalErro = this.abreModalErro.bind(this);
    //     this.fechaModalErro = this.fechaModalErro.bind(this);
    // }
    // componentDidMount() {
    //     let cookie = new Cookies();
    //     let token = cookie.get('token')
    //     let refreshToken = cookie.get('refreshToken')

    //     axios.post('',
    //         {
    //             refreshToken: refreshToken
    //         },
    //         {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer ' + token
    //             },
    //             timeout: 8000
    //         })
    //         .then(res => {
    //             if (res.status === 200) {
    //                 const cookies = new Cookies();

    //                 cookies.set('token', res.data.token, { path: '/' });
    //                 cookies.set('refreshToken', res.data.refreshToken, { path: '/' });
    //             }
    //         })
    //         .catch((error) => {
    //             this.setState({
    //                 tipoErro: error.response.data
    //             });
    //             this.abreModalErro();
    //         })
    // }

    // abreModalErro() {
    //     this.setState({ modalOpenErro: true })
    // }

    // fechaModalErro() {
    //     this.setState({ modalOpenErro: false })
    // }
    render() {
        return (
            <div>
                {/* Alterado *Flor */}
                {/* <Modal  //Modal de erro
                    isOpen={this.state.modalopenErro}
                    onRequestClose={this.fechaModalErro}
                    className="modal-sair col-6 col-md-5"
                    overlayClassName="overlay"
                >
                    <div className="float-center text-center eventodiv">
                        <label> Ops! Temos problemas: </label><br />
                        {this.state.tipoErro.length === 1 ? this.state.tipoErro.map((erro, index) =>
                            <p key={index}>{erro}</p>) : <p>{this.state.tipoErro}</p>}
                        <div className="row">
                            <div className="col">
                                <Link to = "/"><button onClick={this.fechaModalErro} className="btn btn-outline-primary btn-sm col-9 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </Modal> */}
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Switch>
                            <Route exact path="/" component={LoginUsuario} />
                            <Route path="/Home/MarcaPonto" component={Home} />
                            <Route path="/Home/NovaSenha" component={AlterarSenha} />
                            <Route path="*">
                                <Redirect to="/" />
                            </Route>
                        </Switch>
                    </PersistGate>
                </Provider>
                <Footer />
            </div>
        );
    }
}

export default Rotas;
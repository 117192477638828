const initialState = {
    colaborador: [],
    dataUltimaMarcacao: '',
    horaUltimaMarcacao: '',
    historicoMarcacoes: [],
    idEmpresa_Login: '',
    username: '',
    conectado: false
}

const adicionaPontoReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'changeColaborador':
            return { ...state, colaborador: action.payload.colaborador }

        case 'changeUltimaMarcacao':
            return {
                ...state, dataUltimaMarcacao: action.payload.dataUltimaMarcacao,
                horaUltimaMarcacao: action.payload.horaUltimaMarcacao
            }

        case 'changeInitialState':
            return {
                colaborador: [],
                // dataUltimaMarcacao: '',
                // horaUltimaMarcacao: '',
                // historicoMarcacoes: [],
                // idEmpresa_Login: '',
                // username: '',
                // conectado: false
            }

            case 'changeInitialStateLogout':
            return {
                ...state, 
                colaborador: [],
                dataUltimaMarcacao: '',
                horaUltimaMarcacao: '',
                historicoMarcacoes: []
            }

        case 'changeHistoricoMarcacao':
            return { ...state, historicoMarcacoes: action.payload.historicoMarcacoes }

        case 'changeIDEmpresa':
            return { ...state, idEmpresa_Login: action.payload.idEmpresa_Login }

        case 'changeUsername':
            return { ...state, username: action.payload.username }

        case 'changeConectado':
            return { ...state, conectado: action.payload.conectado }

        default:
            return state;

    }
};

export default adicionaPontoReducer;
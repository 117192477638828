import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { IoMdExit } from 'react-icons/io'

import { Redirect } from 'react-router'
import Cookies from 'universal-cookie';
import Modal from 'react-modal';

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap';

import { connect } from 'react-redux';
import { changeInitialStateLogout } from '../actions/pontoAction';

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			horaMarcacao: this.props.horaUltimaMarcacao,
			dataMarcacao: this.props.dataUltimaMarcacao,

			modalSair: false,
			redirectSair: false,
			isOpen: false,
		}

		this.formatData = this.formatData.bind(this);
		this.fechaModal = this.fechaModal.bind(this);
		this.OnClickSair = this.OnClickSair.bind(this);
		this.deslogarSistema = this.deslogarSistema.bind(this);
		this.renderRedirect = this.renderRedirect.bind(this);
		this.setIsOpen = this.setIsOpen.bind(this);
		this.fechaToggle = this.fechaToggle.bind(this);
	}

	setIsOpen() {
		let isOpen = this.state.isOpen
		this.setState({ isOpen: !isOpen })
	}

	fechaToggle() {
		this.setState({ isOpen: false })
	}

	fechaModal() {
		this.setState({ modalSair: false })
	}

	OnClickSair(event) {
		event.preventDefault();

		this.setState({ modalSair: true })
	}

	formatData() {
		let objetoDate = new Date(this.props.dataUltimaMarcacao);

		if (objetoDate.getFullYear() > 1) {
			let dia = objetoDate.getDate();
			dia = ('0' + dia).slice(-2);
			let mes = objetoDate.getMonth() + 1;
			mes = ('0' + mes).slice(-2);
			let ano = objetoDate.getFullYear();

			return dia + '/' + mes + '/' + ano + ' | ';
		}
		else {
			return undefined;
		}
	}

	renderRedirect() {
		this.setState({ redirectSair: true });
	}

	deslogarSistema() {
		let cookies = new Cookies('token');

		cookies.remove('token', { path: '/' });

		this.props.changeInitialStateLogout();

		this.renderRedirect();
	}

	render() {
		if (this.state.redirectSair) {
			return <Redirect to='/' />
		}

		let ultimaMarcacaoFormatada = this.formatData() !== undefined ? this.formatData() + this.props.horaUltimaMarcacao : '';
		return (
			<div>
				<Modal //Modal de saida
					isOpen={this.state.modalSair}
					// onRequestClose={this.fechaModal}
					className="modal-sucesso col-10 col-md-8 col-lg-6 col-xl-4 text-center"
					overlayClassName="overlay"
				>
					<div className="float-center text-center">
						<label> Certeza que deseja sair? </label>
						<div className="row">
							<div className="col text-right">
								<button onClick={this.deslogarSistema} className="btn btn-outline-primary btn-sm col-10 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Sim</button>
							</div>

							<div className="col text-left">
								<button onClick={this.fechaModal} className="btn btn-outline-danger btn-sm col-10 col-sm-6 col-md-5 col-lg-4 col-xl-4 botaoModal">Não</button>
							</div>
						</div>
					</div>
				</Modal>
				<Navbar className="navbarColor" dark expand="sm" onBlur={this.fechaToggle}>
					<NavbarBrand className="mensagemTopo marginNavBar" href="#">
						<span className="mensagemUsuario"> Olá, {this.props.colaborador.Nome} </span>
						<br />
						<span className="mensagemTopo ultimaMarcacao">
							Última Marcação ( {ultimaMarcacaoFormatada} )
							</span>
					</NavbarBrand>
					<NavbarToggler onClick={this.setIsOpen} />
					<Collapse isOpen={this.state.isOpen} navbar>
						<Nav className="ml-auto" navbar>
							<NavItem className="margemNavlink">
								<Link to="/Home/MarcaPonto" className="text-white"> Marcar Ponto </Link>
							</NavItem>
							<NavItem className="margemNavlink">
								<Link to="/Home/MarcaPonto/Historico" className="text-white"> Histórico </Link>
							</NavItem>
						</Nav>
					</Collapse>
					<NavItem className="margemNavlink tiraMarcador">
						<button
							onClick={this.OnClickSair}
							type="button"
							className="btn btn-sair btn-giro paddingBotao"
						>
							<IoMdExit size={32} className="text-white" />
						</button>
					</NavItem>
				</Navbar>
			</div>
		);

	}

}

const mapActionstoProps = {
    changeInitialStateLogout
}

const mapStateToProp = (state) => ({
	colaborador: state.dadosPonto.colaborador,
	dataUltimaMarcacao: state.dadosPonto.dataUltimaMarcacao,
	horaUltimaMarcacao: state.dadosPonto.horaUltimaMarcacao
});

export default connect(mapStateToProp, mapActionstoProps)(Header);

import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import MarcaPontoHS from '../marcaPontoHS/Home';
import Historico from '../historico';
import AlterarSenha from '../senha/mainAltera_senha';
import Header from '../header';

const Home = () => (
    //Alterado *Flor
    <div>
        <Header />
        <Switch>
            <Route exact path="/Home/MarcaPonto" component={MarcaPontoHS} />
            <Route path="/Home/MarcaPonto/Historico" component={Historico} />
            <Route path="/Home/NovaSenha" component={AlterarSenha} />
            <Route path="*">
                <Redirect to="/" />
            </Route>
        </Switch>
    </div>

);

export default Home;
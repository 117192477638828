export const changeColaborador = (colaborador) => {
    return {
        type: 'changeColaborador',
        payload: { colaborador: colaborador }
    };
}

export const changeUltimaMarcacao = (data, hora) => {
    return {
        type: 'changeUltimaMarcacao',
        payload: { dataUltimaMarcacao: data, horaUltimaMarcacao: hora }
    }
}

export const changeInitialState = () => {
    return {
        type: 'changeInitialState',
        payload: {
            colaborador: [],
            dataUltimaMarcacao: '',
            horaUltimaMarcacao: '',
            historicoMarcacoes: [],
            idEmpresa_Login: '',
            username: '',
            conectado: false
        }
    };
}

export const changeInitialStateLogout = () => {
    return {
        type: 'changeInitialStateLogout'
    };
}

export const changeHistorico = (marcacoes) => {
    return {
        type: 'changeHistorico',
        payload: { historicoMarcacoes: marcacoes }
    }
}

export const changeIDEmpresa = (idEmpresa_Login) => {
    return {
        type: 'changeIDEmpresa',
        payload: { idEmpresa_Login: idEmpresa_Login }
    }
}

export const changeUsername = (username) => {
    return {
        type: 'changeUsername',
        payload: { username: username }
    }
}

export const changeConectado = (conectado) => {
    return {
        type: 'changeConectado',
        payload: { conectado }
    }
}